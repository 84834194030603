



































import { defineComponent } from '@vue/composition-api'
import useEmissionStandard from '@/use/emissionStandard'
import useUser from '@/use/user'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({ emissionStandard: '' })
    }
  },

  components: {
    MCompanyFilter: () => import('@/components/molecules/m-company-filter.vue'),
  },

  setup(props, { root }) {
    const { hasAccessTo, hasManyCompanies } = useUser({ root })
    const { emissionStandards } = useEmissionStandard()

    return { hasAccessTo, hasManyCompanies, emissionStandards }
  }
})
